<template>
  <div class="reservation-add-trip-notes">
    <div class="reservation-add-trip-notes--content">
      <div
        v-show="isInDispatchSidebar"
        class="reservation-add-trip-notes--back-arrow"
      >
        <a class="arrow_back" @click="clickBackTripNotes">
          <CRIcon view-box="0 0 24 24" :width="20" :height="20">
            arrow_back
          </CRIcon>
          Back To Reservation
        </a>
      </div>
      <div v-if="isParentReservation">
        Trip Notes
        <CRRichText
          id="reservation-add-trip-notes-form-text-area-trip-notes"
          :note="previousTripNotes.customerNotes"
          class="reservation-add-trip-notes--textbox"
          placeholder="Trip notes for you, the customer, and the operator."
          :rows="3"
          auto-grow
          hide-details
          @htmlchange="updateTripNoteHtml"
          @textchange="updateTripNoteText"
        />
      </div>
      <div v-if="isParentReservation">
        Office Notes
        <CRRichText
          id="reservation-add-office-notes-form-text-area-trip-notes"
          :note="previousTripNotes.officeNotes"
          class="reservation-add-trip-notes--textbox"
          placeholder="Trip notes for internal use only (customers and operators will not see this)."
          :rows="3"
          auto-grow
          hide-details
          @htmlchange="updateOfficeNoteHtml"
          @textchange="updateOfficeNoteText"
        />
      </div>
      <div v-if="isSuperAdmin">
        Driver Notes
        <CRRichText
          id="reservation-add-driver-notes-form-text-area-trip-notes"
          :note="previousTripNotes.driverNotes"
          class="reservation-add-trip-notes--textbox"
          :placeholder="`Trip notes for you and the driver (customers will not see this).`"
          :rows="3"
          auto-grow
          hide-details
          @htmlchange="updateDriverNoteHtml"
          @textchange="updateDriverNoteText"
        />
      </div>
    </div>
    <div class="reservation-add-trip-notes--btn-spacer"></div>
    <v-btn
      v-if="resActiveTrip && enableEditTripNotes"
      :id="`quote-form-trip-delete-trip-notes-dialog-button`"
      color="error"
      outline
      style="margin: 0 0 71px 0; border-radius: 0"
      class="reservation-add-trip-notes--action-btn reservation-add-trip-notes--delete-btn"
      @click="deleteTripNotes"
    >
      Delete
    </v-btn>
    <v-btn
      v-if="enableEditTripNotes"
      :id="`quote-form-trip-set-trip-notes-button`"
      class="reservation-add-trip-notes--action-btn"
      color="primary"
      style="margin: 0; border-radius: 0"
      @click="addTripNotes"
    >
      Save
    </v-btn>
  </div>
</template>

<script>
const INTERNAL_NOTE_TYPE = 1
const CUSTOMER_NOTE_TYPE = 2
const DRIVER_NOTE_TYPE = 3
const PARENT_RESERVATION_TYPE = 0

import { authComputed } from '@/state/helpers'
import { editTripNotes } from '@/services/trips'
import { mapGetters, mapActions } from 'vuex'
import { EventBus } from '@/utils/event-bus'
import { deepClone } from '@/utils/deepClone'

export default {
  props: {
    reservationId: {
      type: Number,
      default: 0,
    },
    trip: { type: Object, default: () => {} },
    reservationType: {
      type: Number,
      default: null,
    },
  },
  data() {
    return {
      loading: false,
      enableEditTripNotes: null,
      resActiveTrip: {},
    }
  },
  computed: {
    ...authComputed,
    ...mapGetters({
      showAddTripNotes: 'dispatchNew/showAddTripNotes',
    }),
    isInDispatchSidebar() {
      return this.showAddTripNotes
    },
    isParentReservation() {
      return this.reservationType === PARENT_RESERVATION_TYPE
    },
    previousTripNotes() {
      let notes = this.resActiveTrip?.tripNotes
      if (notes) {
        let customerNotes = notes.find((note) => {
          return note.noteType === CUSTOMER_NOTE_TYPE
        })
        let officeNotes = notes.find((note) => {
          return note.noteType === INTERNAL_NOTE_TYPE
        })
        let driverNotes = notes.find((note) => {
          return note.noteType === DRIVER_NOTE_TYPE
        })

        let previousNotes = {}
        previousNotes.customerNotes =
          customerNotes?.html ||
          customerNotes?.note ||
          this.resActiveTrip.description
        previousNotes.officeNotes =
          officeNotes?.html || officeNotes?.note || this.resActiveTrip.notes
        previousNotes.driverNotes = driverNotes?.html || driverNotes?.note

        return previousNotes
      }

      return {}
    },
  },
  mounted() {
    this.resActiveTrip = deepClone(this.trip)
    this.enableEditTripNotes = this.isParentReservation
  },
  methods: {
    ...mapActions({
      setShowAddTripNotes: 'dispatchNew/setShowAddTripNotes',
    }),
    clickBackTripNotes() {
      this.setShowAddTripNotes(false)
    },
    updateTripNoteText(value) {
      this.updateNotesForTripIndex(CUSTOMER_NOTE_TYPE, value)
    },
    updateTripNoteHtml(value) {
      this.updateHtmlNotesForTripIndex(CUSTOMER_NOTE_TYPE, value)
    },
    updateOfficeNoteText(value) {
      this.updateNotesForTripIndex(INTERNAL_NOTE_TYPE, value)
    },
    updateOfficeNoteHtml(value) {
      this.updateHtmlNotesForTripIndex(INTERNAL_NOTE_TYPE, value)
    },
    updateDriverNoteText(value) {
      this.updateNotesForTripIndex(DRIVER_NOTE_TYPE, value)
    },
    updateDriverNoteHtml(value) {
      this.updateHtmlNotesForTripIndex(DRIVER_NOTE_TYPE, value)
    },
    updateNotesForTripIndex(noteType, note) {
      if (noteType == CUSTOMER_NOTE_TYPE) {
        this.resActiveTrip.description = note
      }
      if (noteType == INTERNAL_NOTE_TYPE) {
        this.resActiveTrip.notes = note
      }

      let filteredTripNotes = this.resActiveTrip.tripNotes?.find(
        (note) => note.noteType === noteType
      )
      if (filteredTripNotes) {
        filteredTripNotes.note = note
      } else {
        if (this.resActiveTrip.tripNotes == null) {
          this.resActiveTrip.tripNotes = []
        }
        this.resActiveTrip.tripNotes.push({
          note: note,
          createdBy: this?.currentUser?.userId,
          noteType: noteType,
        })
      }
    },
    updateHtmlNotesForTripIndex(noteType, html) {
      let filteredTripNotes = this.resActiveTrip.tripNotes?.find(
        (note) => note.noteType === noteType
      )
      if (filteredTripNotes) {
        filteredTripNotes.html = html
      } else {
        if (this.resActiveTrip.tripNotes == null) {
          this.resActiveTrip.tripNotes = []
        }
        this.resActiveTrip.tripNotes.push({
          html: html,
          createdBy: this?.currentUser?.userId,
          noteType: noteType,
        })
      }
    },
    addTripNotes() {
      this.$set(this.trip, 'description', this.resActiveTrip.description)
      this.$set(this.trip, 'notes', this.resActiveTrip.notes)
      this.$set(this.trip, 'tripNotes', this.resActiveTrip.tripNotes)
      this.submitTripNotesUpdate()
      if (this.isInDispatchSidebar) {
        this.setShowAddTripNotes(false)
      } else {
        this.close()
      }
    },
    deleteTripNotes() {
      this.resActiveTrip.description = ''
      this.resActiveTrip.notes = ''
      this.updateTripNoteText('')
      this.updateOfficeNoteText('')
      this.updateTripNoteHtml('')
      this.updateOfficeNoteHtml('')
      if (this.isSuperAdmin) {
        this.updateDriverNoteText('')
        this.updateDriverNoteHtml('')
      }
      this.$set(this.trip, 'description', this.resActiveTrip.description)
      this.$set(this.trip, 'notes', this.resActiveTrip.notes)
      this.$set(this.trip, 'tripNotes', this.resActiveTrip.tripNotes)
      this.submitTripNotesUpdate()
      if (this.isInDispatchSidebar) {
        this.setShowAddTripNotes(false)
      } else {
        this.close()
      }
    },
    async submitTripNotesUpdate() {
      const payload = {
        description: this.resActiveTrip.description,
        notes: this.resActiveTrip.notes,
        tripNotes: this.resActiveTrip.tripNotes,
      }
      if (this.reservationId !== 0) {
        let res = await editTripNotes(this.resActiveTrip.tripId, payload)
        if (res?.data?.trip?.tripNotes) {
          this.$set(this.trip, 'tripNotes', res.data.trip.tripNotes)
        }
      }
      EventBus.$emit('refresh-detail')
      EventBus.$emit('referral-res-detail-update')
      this.$forceUpdate()
    },
    close() {
      this.$store.dispatch('app/closeDialog')
    },
  },
}
</script>

<style lang="scss" scoped>
.reservation-add-trip-notes {
  height: 100%;
  width: 500px;

  &--content {
    flex: 1;
    margin: 40px;
  }

  &--textbox {
    margin-top: 10px;
    margin-bottom: 20px;
  }

  &--back-arrow {
    a {
      display: flex;
      align-items: center;

      .cr-icon {
        margin-left: 4px;
        margin-right: 18px !important;
      }
    }
    margin-top: -10px;
    margin-bottom: 15px;
  }

  &--btn-spacer {
    min-height: 71px;
    width: 100%;
  }

  &--delete-btn {
    border: none;
  }

  &--action-btn {
    display: flex;
    position: fixed;
    flex-direction: column;
    font-size: 18px;
    color: white;
    bottom: 0;
    width: inherit;
    height: 71px;
    padding: 24px 0;
    text-align: center;
    font-weight: bold;
    cursor: pointer;
    width: inherit;
  }
}

.arrow_back {
  color: $black;
  height: 24px;
  margin-right: 8px;
}
</style>
